import React, { useState } from "react";
import S1 from "../img/services/s1.jpg";
import S2 from "../img/services/s2.jpg";
import S3 from "../img/services/s3.jpg";
import S4 from "../img/services/s4.jpg";
import S5 from "../img/services/s5.jpg";
import S6 from "../img/services/s6.jpg";
import { AiOutlineCheck } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";

const Services = () => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);

  const handleOpen = () => setOpen(!open);
  const handleOpen2 = () => setOpen2(!open2);
  const handleOpen3 = () => setOpen3(!open3);
  const handleOpen4 = () => setOpen4(!open4);
  const handleOpen5 = () => setOpen5(!open5);
  const handleOpen6 = () => setOpen6(!open6);

  const services = [
    {
      title: "ROOFING",
      description: "12 years of Experience",
      paragraph: `Our corrugated metal roofs are available in ROOFING with a PVDF paint finish. PVDF resin-based coatings offer long-lasting performance in demanding architectural coating applications. PVDF (polyvinylidene fluoride) resins are used by licensed industrial paint manufacturers to formulate tough, long-lasting coatings. We have an extensive catalog and personalized advice to be able to leave your project in the best hands, check it out!`,
      img: S1,
      id: 1,
    },
    {
      title: "GUTTER",
      description: "We Speak English and Spanish",
      paragraph: `At Perdomo Roofing Coorp, we are specialists in Standing Seam Metal Roof Panels, which we fasten together with hidden fasteners. Originally, the clips fit the vertical legs and were used to fasten the panel to the ceiling substructure. However, as panel options have increased, it is common to see clipless panels included in this family of products.

      Perdomo Roofing Coorp Offers a variety of standing seams that can be curved on the jobsite, reducing transportation costs and ensuring a proper fit. Contact the experts, with the best prices and guaranteed material.
      `,
      img: S2,
      id: 2,
    },
    {
      title: "SIDING",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `TPO roofs are becoming increasingly popular for commercial properties due to their strength and durability. This low-maintenance material can last for many years if properly cared for and maintained, often outlasting traditional materials such as asphalt shingle roofing. TPO roofs are made from thermoplastic polyolefin (TPO) rubber or PVC plastic with an added layer of protective coating, making them less susceptible to damage from hazardous elements such as UV rays, extreme temperatures, pollutants, high winds, and fire. . At Perdomo Roofing Coorp, we make these qualities of TPO roofs an excellent long-term investment that offers a variety of environmental benefits, such as increased energy efficiency and a lower carbon footprint. Contact the professionals for a free inspection.`,
      img: S3,
      id: 3,
    },

    {
      title: "WHAT IS EPDM?",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `EPDM is an extremely durable synthetic rubber (ethylene propylene diene terpolymer) roofing membrane widely used on low-slope buildings in the United States and around the world. Its two main ingredients, ethylene and propylene, are derived from petroleum and natural gas. EPDM is available in black and white, and is sold in a wide variety of widths, ranging from 7.5' to 50', and in two thicknesses, 45 and 60 mils. EPDM can be installed either fully adhered, mechanically bonded, or ballasted, with the roofing system seams sealed with liquid adhesives or specially formulated tape. We are the professionals for which we instruct you in the best materials and the best option for your home or business, Perdomo Roofing Coorp, the most complete company in all of Vergne, TN and its surroundings areas. `,
      img: S4,
      id: 4,
    },
    {
      title: "TPO ROOFING SERVICES",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `If you're looking to install or repair your roof, an asphalt roof installation or repair is a great option, especially if you're working on a tight budget. You can choose from two different types of shingles: 3-tab shingles are typically the more economical option, with a slim, flat design, while dimensional shingles offer more protection and a sleeker finish. At Perdomo Roofing Coorp, we accommodate our clients' budgets, since the cost of installing asphalt shingles is relatively low compared to other options such as metal or tiles, so it is worth hiring a professional to do it. do for you. Call now and receive a free inspection or an estimate, we are the professionals when it comes to roofing.`,
      img: S5,
      id: 5,
    },
    // {
    //   title: "Other Services",
    //   description: "We Accepts Venmo, Paypal, Zelle, Cash App",
    //   paragraph: `General Roofing`,
    //   img: S6,
    //   id: 6,
    // },
  ];

  const selectServices = (id) => {
    switch (id) {
      case 1:
          handleOpen();
        break;
      case 2: 
        handleOpen2();
      break;

      case 3: 
        handleOpen3();
      break;
      case 4: 
        handleOpen4();
      break;
      case 5: 
        handleOpen5();
      break;
      case 6: 
        handleOpen6();
      break;
      default:
        break;
    }
  }

  return (
    <div className="overflow-y-scroll">
      <div className="header-title px-24 py-32">
        <Link to={"/"} className="text-[25px] text-white">
          Home
        </Link>
        <h2 className="font-bold text-[60px] text-white">Services</h2>
      </div>
      <div className="py-24 lg:px-32 px-2">
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
          {services.map((ser) => (
            <div className="">
              <img
                width="100%"
                className="h-[60%]"
                src={ser.img}
                alt=""
                srcset=""
              />
              <div className="flex bg-gray-900 py-8 px-4 w-full items-center">
                <h2 className="font-bold text-[#ffffff]">{ser.title}</h2>
                <div className="flex mx-4">
                  <button onClick={() => selectServices(ser.id)} class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded ">
                    +
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div
          className="grid lg:grid-cols-3 grid-cols-1 gap-4 mt-16 "
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        >
          <p className="col-span-2 text-gray-600">
            Do you need a professional roofing job for your home? At Perdomo
            Roofing Corp we have a variety of services in this industry and 12
            years of experience that support our services, in addition to having
            a highly professional and qualified team so that they can provide
            support when requesting one of our different roofing services.
          </p>
          <div className="col-span-1">
            <h2 className="font-bold text-2xl">Our Advantages</h2>
            <ul className="mt-4">
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-red-700 font-bold" />
                  <p className="text-gray-600">Free Estimates</p>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-red-700 font-bold" />
                  <p className="text-gray-600">We Speak English and Spanish</p>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-red-700 font-bold" />
                  <p className="text-gray-600">WE COVER 50 MILES</p>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-red-700 font-bold" />
                  <p className="text-gray-600">12 years of Experience</p>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
      </div>

      <Dialog  size='lg' open={open} handler={handleOpen}>
        <DialogHeader>ROOFING</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S1} alt="" srcset="" />
            <Typography className="font-normal">
            More than just roofing we are dedicated to Safety and Quality!
            If your roof looks a little worn or you have constant repairs, it's time to replace it.
            If damaged tiles can cause water ingress.
            Severe water damage can lead to mold growth and wood rot, which can damage the structural structure of your roof.
            We want to give you the peace of mind that comes from trusting an experienced roofing company, a wide range of roofing services, and the security of a comprehensive warranty.
            Our roofers have the training and experience you can trust!
            We're the best choice for home improvements, especially roofing, and offer several options to fit any homeowner's budget.
            Whichever roofing style you prefer, your project manager will help you make the right choice for your home.
            The important thing is that your roofing system has ALL the products it needs to function properly, not just shingles. That includes a solid roof deck, proper gutters, gutter covers, flashing, drip edge, subfloor and moisture barrier, as well as proper ventilation and insulation.
            You can call us and contact the best customer service.

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open2} handler={handleOpen2}>
        <DialogHeader>GUTTER</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S2} alt="" srcset="" />
            <Typography className="font-normal">
              Service and quality are not expensive, they are priceless!
              Gutter installation company in Kentucky, North Carolina.
              When you think about the things your home or business needs, you may not be thinking about gutters. However, it is important to note that gutters make a difference in the strength of your structure over time.
              Rainwater can wreak havoc on a home or business if its flow is not controlled by properly installed or maintained gutters.
              If your building does not have gutters or the current ones are old, contact us to remedy the situation and protect your home. Gutters enhance your property in a number of ways, including:

              Signs it's time to install new gutters
              How do you know you need new gutters?
              To begin with, it is very important to replace the gutters when you notice holes or other significant damage to the gutters. Also, it's important to replace your gutters if you're doing major repairs or a roof replacement.
              Changes to your roof might not only alter the way your water flows, but your roof and gutters might not match up like they used to.
                If your home doesn't have gutters to begin with, it's definitely time you called our team for a high-quality gutter installation. <br />

              With over 15 years of industry experience, the team at our gutter installation company has the installation equipment and product selection to get the job done right the first time. We understand that the work we do on your home or business is meant to save you money and protect your structure and foundation. That's why we take the time to thoroughly assess the condition of your home and identify any issues that could cause problems with the installation process. You can trust us for any of the specialized services:
            <br />
              Seamless gutter installation <br />
              Installation of rain gutters <br />
              gutter downspout installation <br />
              gutter cover installation <br />
              gutter repairs <br />
              gutter screen installation

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen2}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open3} handler={handleOpen3}>
        <DialogHeader>SIDING
</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S3} alt="" srcset="" />
            <Typography className="font-normal">
              THE BEST SIDING OPTION
              LOOKING FOR EXPERTS IN SIDING ?
              Contact us for questions, more information and estimates.
              Made primarily from PVC resins, vinyl siding offers the benefits of affordability, immunity to mold and moisture damage, and a host of styles, textures, and colors to choose from. Vinyl siding is nearly indestructible and is designed to retain its color even during extreme exposure to the elements.
                Get a great coating product with no painting or maintenance required.
              The only expense you need to worry about is the installation cost. Enhance your home's value and curb appeal with new siding from The Best Roofing Contracting.
              Aesthetically attractive and highly energy efficient with oriented strand boards or foam (noise reduction effect).
              High performance attributes that withstand winds up to 150 MPH and storms. Guaranteed against rotting, warping and delamination.

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen3}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open4} handler={handleOpen4}>
        <DialogHeader>WHAT IS EPDM?</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S4} alt="" srcset="" />
            <Typography className="font-normal">
            EPDM is an extremely durable synthetic rubber roofing membrane widely used in low-slope buildings.
              It can be installed fully adhered, mechanically attached or ballasted, with roofing system seams sealed with liquid adhesives or a specially formulated tap.
              The Best Roofing Contracting has been offering superior roofing solutions. Unmatched service, expert knowledge and versatility in all areas of roofing.
            ​One area of versatility comes in the form of the EPDM roofing solutions that we offer to our commercial and residential roofing customers.
            ​The overall superior quality, durability, and cost-effectiveness of EPDM roofing membranes are the primary characteristics that make EPDM a sought-after roofing option for many commercial roofing projects.
            ​With over 15 years of commercial roofing experience, there isn't a type of commercial roof that we haven't installed, repaired or replaced. Because it is a specialized type of roofing material, EPDM roofing installation requires expert knowledge, training, and experience to do a flawless job.
            Our roofing experts have an in-depth knowledge of experience in the area of professional EPDM roof installation, repair and replacement.
              EPDM roof installation is a very detailed procedure. Without too much technical jargon, some of the many steps involved in installing EPDM roofs include: stripping and preparing the roof surface, applying the EPDM adhesive, spreading the glue over the adhesive, trimming where necessary, checking that airbags and more.
              We are committed to keeping our clients' buildings watertight, thus protecting the properties and their inhabitants. From roof inspection to installation, along with repair and maintenance services, our roofing experts aim to deliver an excellent product and great attention to detail.

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen4}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open5} handler={handleOpen5}>
        <DialogHeader>TPO ROOFING SERVICES</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S5} alt="" srcset="" />
            <Typography className="font-normal">
              Need a TPO Roofing Specialist?
              To get started, simply fill out our online form or contact us directly.
                We're here to answer your questions and put you at ease with a superior service and experience you won't find anywhere else.
              TPO roofs offer an attractive and durable material that keeps electricity bills low and carbon emissions even lower.
              When most people see the word "thermoplastic" they think of plastic, such as PVC. But it actually consists of a combination of ethylene propylene rubber, which makes it a flexible solution.
              Considered both cost effective and energy efficient, TPO is ideal for flat roofs or properties that feature a low pitched roof at a cost effective price.
              we can help you protect the integrity of your roof membrane with our experienced TPO roofing services.
              Our services include:
              inspect welds
              Inspection of drains and scuppers.
              Inspection of terminations in all RTUs, HVAC units, pipes and parapets.
              Inspect the metal of the lid

              Any roof, of any type, must be installed correctly to be effective. A professional roofer will have the experience and knowledge to install your roof correctly, ensuring that it will provide optimal protection for your home.
              There are a variety of roofing materials to choose from, each with its own set of benefits. A professional roofer can help you select the right material for your home and then install it correctly, ensuring many years of trouble-free use. Whether you need to install a new roof or repair an existing roof, entrusting the job to a professional roofing contractor is always the best course of action.

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen5}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open6} handler={handleOpen6}>
        <DialogHeader>Other Services</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S6} alt="" srcset="" />
            <Typography className="font-normal">
            General Roofing
            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen6}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Services;
