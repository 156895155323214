import React from "react";
import {
  Typography,
} from "@material-tailwind/react";
import Back1 from "../img/call.jpg";
import { Link } from "react-router-dom";

const ComponentFr = () => {
  return (
    <div className="lg:h-3/5">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-0 lg:h-3/5">
        <div className="lg:block hidden">
          <img src={Back1} className=" w-full" />
        </div>
        <div className="bg-contact p-16">
          <h2 className="text-[25px] text-red-900 font-bold">
            You Are Our #1 Priority
          </h2>
          <Typography className="text-white text-[22px]  font-medium">
            <span className="text-[55px] text-white font-bold">
              We Love What We Do
            </span>
            <br />
            #1 company in residential and commercial ROOFING SERVICES! <br />
            Hey! The deteriorated roof of your home is a very serious problem. <br />
            We advise you in the transformation process and guarantee the quality and excellence that distinguish us as the best roofers
              We provide excellent workmanship and complete customer satisfaction from start to finish of a project. <br />
            request an appointment for a free evaluation of the condition of the roof of your house. <br />
            call today.

          </Typography>
          <br />
          <Link className="bg-red-800 hover:bg-red-900 text-white font-bold py-2 px-4 rounded">
            Free Estimates
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ComponentFr;
